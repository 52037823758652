// projectImages.js

import airportSecurityFilter from "../assets/projects/airportSecurityFilter.png";
import cisnadieStripMall from "../assets/projects/cisnadieStripMall.png";
import hotelPucioasa from "../assets/projects/hotelPucioasa.png";
import privateHouseBucharest from "../assets/projects/privateHouseBucharest.png";
import privateHouseHarman from "../assets/projects/privateHouseHarman.png";
import publicKindergardenSibiu from "../assets/projects/publicKindergardenSibiu.png";

const images = {
  airportSecurityFilter,
  cisnadieStripMall,
  hotelPucioasa,
  privateHouseBucharest,
  privateHouseHarman,
  publicKindergardenSibiu,
};

export default images;
