import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { IconButton } from '@mui/material';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    imgPath:
      'https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1654178182571-LWMPO8S9VQW6NUSIJN0S/EFFEKT_BIOME_%C2%A9EFFEKT_.jpg?format=2500w',
  },
  {
    label: 'Bird',
    imgPath:
      'https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1654178243106-Y1TI4TP8F2EKDY7VVVFM/EFFEKT_BIOME_%C2%A9EFFEKT_7.jpg?format=2500w',
  },
  {
    label: 'Bali, Indonesia',
    imgPath:
      'https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1654178266904-BLN3XQ53O4VMWMWGY38O/EFFEKT_BIOME_%C2%A9EFFEKT_10.jpg?format=2500w',
  },
  {
    label: 'Goč, Serbia',
    imgPath:
      'https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1654178443391-GDDM09U7SG48L0ID9XW5/EFFEKT_BIOME_%C2%A9EFFEKT_36.jpg?format=2500w',
  },
];

const ImageCarousel = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: '100%', flexGrow: 1, position: 'relative' }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: '100%',
                  display: 'block',
                  maxWidth: '100%',
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 16px',
        }}
      >
        <IconButton
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{ color: 'white' }}
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
          sx={{ color: 'white' }}
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={<div />}
        backButton={<div />}
      />
    </Box>
  );
};

export default ImageCarousel;