import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
//import { ContactForm } from "./contactForm";
import { ContactForm } from "./contactForm";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ModalForm() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="outlined"
        size="large"
        sx={{
          color: "black",
          borderColor: "black",
          borderWidth: "2px",
          borderRadius: 0,
          fontWeight: "bold",
          mt: 2, // Add top margin to the button
          "&:hover": {
            borderColor: "black",
            backgroundColor: "grey",
            borderWidth: "2px",
          },
        }}
      >
        CONTACT US
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* Close button */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8, // Adjust the position as needed
              top: 8, // Adjust the position as needed
              color: (theme) => theme.palette.grey[500], // Use theme colors for better consistency
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography id="modal-modal-title" variant="h6" component="h2">
            Contact Form
          </Typography>
          <ContactForm closeModal={handleClose} />
        </Box>
      </Modal>
    </div>
  );
}
