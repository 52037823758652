// ContactForm.tsx
import { Button, TextField } from "@mui/material";
import React from "react";
import { useFormControls } from "./contactFormControls";
import { PostContactForm } from "../hooks/useFormControls";
import { FormValues, FormErrors } from "../types/general"; // Import the types

const inputFieldValues = [
  {
    name: "fullName",
    label: "Full Name",
    id: "my-name",
  },
  {
    name: "email",
    label: "Email",
    id: "my-email",
  },
  {
    name: "message",
    label: "Message",
    id: "my-message",
    multiline: true,
    rows: 10,
  },
];

export const ContactForm: React.FC<{ closeModal: () => void }> = ({
  closeModal,
}) => {
  const { handleInputValue, handleFormSubmit, formIsValid, errors } =
    useFormControls();

  // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   if (formIsValid()) {
  //     await PostContactForm(
  //       inputFieldValues,
  //       () => {
  //         // Handle success, close modal and reset the form or show a success message
  //         closeModal();
  //       },
  //       () => {
  //         // Handle error, show an error message
  //       }
  //     );
  //   }
  // };

  return (
    <form autoComplete="off" onSubmit={(e) => handleFormSubmit(e, closeModal)}>
      {inputFieldValues.map((inputFieldValue, index) => {
        return (
          <TextField
            variant="standard"
            key={index}
            onChange={handleInputValue}
            onBlur={handleInputValue}
            name={inputFieldValue.name}
            label={inputFieldValue.label}
            error={!!errors[inputFieldValue.name]}
            multiline={inputFieldValue.multiline ?? false}
            fullWidth
            rows={inputFieldValue.rows ?? 1}
            autoComplete="none"
            sx={{ marginBottom: 2 }} // Add bottom margin to each TextField
            {...(errors[inputFieldValue.name] && {
              error: true,
              helperText: errors[inputFieldValue.name],
            })}
          />
        );
      })}
      <Button
        variant="contained"
        type="submit"
        color="secondary"
        disabled={!formIsValid()}
        sx={{ marginTop: 2 }} // Add top margin to the Button
      >
        Send Message
      </Button>
    </form>
  );
};
