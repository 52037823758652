import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px", // Adjust the height as needed
};

const center = {
  lat: 44.433618114518, // Latitude for Bucharest, Romania
  lng: 26.13636582591992, // Longitude for Bucharest, Romania
};

const Map = () => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyAOajNGo_3hfs1ChnVYwMYhJfUeXFAiqcQ">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
        {/* Marker for the specific address */}
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
