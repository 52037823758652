import React from "react";
import { Link, useLocation } from "react-router-dom";
import homepage from "../data/homepage";
import { Grid, Button } from "@mui/material";
import ImageHome from "../components/imageHome";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon
import useMediaQuery from "@mui/material/useMediaQuery"; // Import useMediaQuery
import { useTheme } from "@mui/material/styles"; // Import useTheme
import logoTriptic from "../assets/logoTriptic.png";

const pages = ["work", "office", "contact"];

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const [isNavOpen, setIsNavOpen] = React.useState(false);

  const handleToggleNavMenu = () => {
    setIsNavOpen(!isNavOpen);
  };

  const isActiveLink = (page: any) => {
    return location.pathname === `/${page}`;
  };
  return (
    <>
      {!isMobile && ( // Render this part only if it's not mobile
        <Grid container spacing={3}>
          {homepage.map((page) => (
            <Grid item key={page._id} xs={12} sm={6} md={4}>
              <ImageHome page={page} />
            </Grid>
          ))}
        </Grid>
      )}
      {isMobile && ( // Render this part only on mobile
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "white",
            zIndex: theme.zIndex.modal,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              px: 2,
              py: 1,
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                pt: 3,
              }}
            >
              <Link to="/" style={{ textDecoration: "none" }}>
                <Box
                  component="img"
                  src={logoTriptic}
                  alt="TRIPTIC Logo"
                  sx={{
                    maxWidth: "130px", // Adjust the size as needed
                  }}
                />
              </Link>
            </Box>
          </Box>

          {pages.map((page) => (
            <Button
              key={page}
              // onClick={handleToggleNavMenu}
              component={Link}
              to={`/${page}`}
              sx={{
                my: 2,
                fontFamily: "monospace",
                fontWeight: 700,
                fontSize: "40px",
                letterSpacing: "0px",
                textTransform: "uppercase",
                lineHeight: "100%",
                color: isActiveLink(page) ? "black" : "#888", // Active link is black, others are #888
                textDecoration: "none",
                transition: "color .24s ease-out",
                "&:hover": {
                  color: "black", // Hover color is black
                },
              }}
            >
              {page}
            </Button>
          ))}
        </Box>
      )}
    </>
  );
};

export default Home;
