import React from "react";
import Banner from "../components/banner";
import banners from "../data/banners";
import TeamMember from "../components/teamMember";
import team from "../data/team";
import { Link, useLocation } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import imageGhidScoala from "../assets/files/ghid-scoala.png";
//import ghidScoala from "../../public/files/07_ARH_CJ_GHID 2022_WORLD BANK_GILAU_FinalD.pdf";
//import ghidScoala from "public/files/07_ARH_CJ_GHID 2022_WORLD BANK_GILAU_FinalD.pdf";

const Office = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box id={banners.profile.title} sx={{ my: 4 }}>
          <Banner
            imageUrl={banners.profile.image}
            text={banners.profile.title}
          />
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              {/* Left column (empty) */}
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
              >
                Triptic was founded in 2013 by two architects, Aurel Basuc and
                Adrian Tutuianu, along with business developer Cristian Danciu.
                We set out on a mission to revolutionize the design and
                construction industry by infusing it with creativity and
                innovation. With unwavering dedication, we apply our collective
                expertise, interdisciplinary perspectives, and local knowledge
                to tackle complex challenges in a new and fresh manner.
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
              >
                Whether engaged at any phase of a project or involved throughout
                its entirety, we take pride in our role as catalysts of creative
                vision and technical excellence. Our aim is to surpass
                expectations by delivering exceptional resources and services to
                our clients. We are committed to enhancing the places where
                people live and work, creating environments that nurture and
                inspire. Through our work, we strive to contribute to a
                sustainable world, one in which all individuals will flourish.
              </Typography>
              <h2>ABOUT US</h2>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
              >
                Triptic is a forward-thinking architectural design firm
                dedicated to reimagining spaces and inspiring possibilities with
                a strong emphasis on environmental responsibility. With our team
                of passionate and experienced professionals, we specialize in
                creating unique, sustainable, and transformative designs that
                exceed expectations. Our unwavering commitment to precision,
                meticulous attention to detail, and collaborative ethos has
                positioned us as a trusted leader in the architectural industry
                in Romania.
              </Typography>
              <h2>OUR VISION</h2>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
              >
                We envision crafting architectural projects that seamlessly
                merge aesthetics, functionality, and sustainability,
                transforming spaces into comfortable experiences that leave a
                lasting impact on both occupants and the environment. With a
                deep-rooted commitment to environmental stewardship, we
                prioritize green solutions and strive to minimize the carbon
                footprint of all our projects.
              </Typography>
              <h2>OUR MISSION</h2>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
              >
                Our mission is to deliver unparalleled architectural design
                services that harmoniously integrate aesthetics, functionality,
                and sustainability. We go beyond conventional boundaries,
                pushing creative limits, and embracing innovative approaches to
                ensure our designs are not only visually stunning but also
                environmentally conscious. By placing our clients’ dreams at the
                forefront of every design endeavour, we create spaces that not
                only fulfil their aspirations but also contribute to a greener
                and more sustainable future.
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              {/* Right column (empty) */}
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box id={banners.services.title} sx={{ my: 4 }}>
          <Banner
            imageUrl={banners.services.image}
            text={banners.services.title}
          />
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              {/* Left column (empty) */}
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
              >
                As a one-stop-shop company, Triptic offers comprehensive
                architectural services, providing end-to-end assistance to
                clients throughout the project lifecycle. Depending on the
                project’s complexity, we coordinate a team of licensed
                professionals with expertise in various domains to deliver
                specialized services.
              </Typography>

              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
              >
                We leverage a diverse range of industry-leading software tools
                to bring visionary designs to life. With expertise in utilizing
                advanced software such as Revit, AutoCAD, R hino, Grasshopper,
                3ds M ax, Lumion, SketchUp, Photoshop, InDesign, and V-Ray, we
                push the boundaries of architectural creation and deliver
                exceptional results.
              </Typography>

              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
              >
                Our proficiency in Revit empowers us to create detailed and
                accurate building information models (BIM) that streamline the
                design and construction process. This software enables us to
                efficiently collaborate with project stakeholders, produce
                comprehensive documentation, and ensure precise coordination
                between different disciplines.
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              {/* Right column (empty) */}
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box id={banners.employment.title} sx={{ my: 4 }}>
          <Banner
            imageUrl={banners.employment.image}
            text={banners.employment.title}
          />
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ p: 2 }}>
              <h2>JOBS</h2>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{
                  lineHeight: "1.8",
                  color: "grey",
                  pb: 2,
                  fontWeight: 400,
                }}
              >
                All applications (solicited and unsolicited) should be submitted
                through our application system below.
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{
                  lineHeight: "1.8",
                  color: "grey",
                  pb: 2,
                  fontWeight: 400,
                }}
              >
                We review all applications on a bi-weekly basis and store
                relevant applications for a maximum period of 6 months after
                which they are deleted.
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{
                  lineHeight: "1.8",
                  color: "grey",
                  pb: 2,
                  fontWeight: 400,
                }}
              >
                Submitted files should be in .pdf format only and not exceed a
                size of 10 Mb.
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", pb: 2 }}
              >
                For more information about working at EFFEKT please visit:
              </Typography>
              <Button
                variant="outlined"
                size="large"
                component={Link}
                to={`/working-at-triptic`}
                sx={{
                  color: "black",
                  borderColor: "black",
                  borderWidth: "2px", // Increase border width
                  borderRadius: 0, // Set border-radius to 0 for no border-radius
                  fontWeight: "bold", // Make the text bolder
                  "&:hover": {
                    borderColor: "black", // Ensure the border color remains black on hover
                    borderWidth: "2px", // Maintain the border width on hover
                    backgroundColor: "transparent", // Optional: Set a transparent background on hover
                  },
                }}
              >
                working at TRIPTIC
              </Button>
            </Grid>
            <Grid item xs={12} md={6} sx={{ p: 2 }}>
              <h2>INTERNSHIP</h2>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", pb: 2 }}
              >
                We are always looking for talented students of architecture,
                landscape architecture, urban planning, and constructing
                architects to join our team.
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", pb: 2 }}
              >
                We accept 6 - 8 interns during the spring and the fall semester,
                which run from August through January and from February through
                June.
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", pb: 2 }}
              >
                We do not offer summer internships and only students enrolled at
                an accredited university are eligible to apply for an
                internship.
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", pb: 2 }}
              >
                The Architecture Internship positions for the Fall 2024 semester
                have been filled. This excludes the Innovation Intern and the
                Business Development Intern position which are still active.
              </Typography>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  color: "black",
                  borderColor: "black",
                  borderWidth: "2px", // Increase border width
                  borderRadius: 0, // Set border-radius to 0 for no border-radius
                  fontWeight: "bold", // Make the text bolder
                  "&:hover": {
                    borderColor: "black", // Ensure the border color remains black on hover
                    borderWidth: "2px", // Maintain the border width on hover
                    backgroundColor: "transparent", // Optional: Set a transparent background on hover
                  },
                }}
              >
                APPLY NOW
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box id={banners.people.title} sx={{ my: 4 }}>
          <Banner imageUrl={banners.people.image} text={banners.people.title} />
        </Box>
      </Grid>
      {/* Team Members Grid */}
      {team.map((member) => (
        <Grid item xs={12} sm={6} md={4} key={member._id}>
          <TeamMember
            name={member.name}
            job={member.job}
            image={member.image}
            email={member.email}
            telefon={member.telefon}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Box id={banners.publications.title} sx={{ my: 4 }}>
          <Banner
            imageUrl={banners.publications.image}
            text={banners.publications.title}
          />
        </Box>
        <Grid container spacing={isMobile ? 0 : 2}>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={imageGhidScoala}
              alt="Description of Image"
              sx={{ width: "100%", height: "auto" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column", // Stack children vertically
                justifyContent: "center", // Center vertically
                alignItems: "center", // Center horizontally
                height: "100%", // Take full height of the parent
                textAlign: "center", // Center text alignment
                p: isMobile ? 2 : 4, // Add padding
              }}
            >
              <Typography variant="h4" gutterBottom>
                Liceul Teoretic Gelu Voievod Gilau
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", pb: 2 }}
              >
                Se propun o serie de module stan- dard pentru amenajarea și
                utilarea spațiilor de joacă, a spatiilor publice conexe și a
                curții școlii. Aceste module reprezintă fiecare grupă de
                instalații sau echipamente ce se doresc amplasate în context. În
                total s-au identificat 15 grupe di- ferite de echipamente ce au
                rolul de a deservi toate categoriile de vârstă (atât pentru
                copii cât și pentru adulți).
              </Typography>
              <a
                href="/files/07_ARH_CJ_GHID 2022_WORLD BANK_GILAU_FinalD.pdf"
                download
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="outlined"
                  size="large"
                  sx={{
                    color: "black",
                    borderColor: "black",
                    borderWidth: "2px", // Increase border width
                    borderRadius: 0, // Set border-radius to 0 for no border-radius
                    fontWeight: "bold", // Make the text bolder
                    "&:hover": {
                      borderColor: "black", // Ensure the border color remains black on hover
                      borderWidth: "2px", // Maintain the border width on hover
                      backgroundColor: "transparent", // Optional: Set a transparent background on hover
                    },
                  }}
                >
                  Download guide
                </Button>
              </a>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box id={banners.awards.title} sx={{ my: 4 }}>
          <Banner imageUrl={banners.awards.image} text={banners.awards.title} />
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              {/* Left column (empty) */}
            </Grid>
            <Grid item xs={12} md={8}>
              <h2>#Best 100 Architec ture Offices in Romania Award</h2>

              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
              >
                Triptic has garnered recognition and acclaim within the Romanian
                architectural landscape. Notably, the studio was honored with a
                nomination in the prestigious “Best 100 Architecture Offices of
                2022” by IBS Focus Romania. With a decade of experience, Triptic
                Studio has left an indelible mark by successfully designing over
                500,000 square meters of diverse and captivating buildings
                encompassing both private and public architecture.
              </Typography>

              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey" }}
              >
                Our commitment to sustainability is evident not only in the
                architectural prowess but also in our partnership with
                Charger.ro, the pioneering integrator of solutions and services
                for the green energy industry in Romania.
              </Typography>

              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey" }}
              >
                As co-founders of Charger.ro, we bring unparalleled expertise in
                sustainable energy solutions to the architectural projects.
                Charger.ro leads the way in providing a diverse range of
                verified offerings, from charging stations for electric and
                plug-in hybrid vehicles to on-grid and off-grid photovoltaic
                systems, along with Smart City solutions. By collaborating with
                Charger.ro, Triptic expands its portfolio with sustainable
                design solutions, blending architectural excellence with
                environmentally conscious practices.
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey" }}
              >
                Together, Triptic and Charger.ro are shaping a greener future by
                integrating sustainable energy solutions seamlessly into their
                architectural designs. Their shared vision encompasses not only
                creating visually and functional spaces but also leaving a
                positive impact on the environment and promoting sustainable
                practices for individuals and companies alike.
              </Typography>
              <h2>World Bank Consultants in Urban Design</h2>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey" }}
              >
                We stand at the forefront of revolutionizing public spaces
                across Romania. Our team’s unwavering dedication and expertise
                have enabled us to coordinate so far a series of 10 influential
                guidelines for the development of public spaces in the country.
                These guidelines serve as a comprehensive roadmap, addressing
                key aspects such as accessibility, inclusivity, functionality,
                and aesthetics, to transform public spaces into vibrant,
                engaging, and socially cohesive environments. In the process, we
                engage closely with clients, stakeholders, and local
                communities, recognizing the intrinsic value of their input in
                shaping public spaces. By fostering open communication and
                integrating diverse perspectives, we create urban design
                solutions that resonate with the unique needs, aspirations, and
                identities of the communities they serve.
              </Typography>

              <h2>Sustainability and Innovation</h2>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey" }}
              >
                As advocates for sustainable development, we infuse our projects
                with environmentally responsible practices. We leverage
                innovative approaches and incorporate sustainable elements to
                minimize environmental impact, promote energy efficiency, and
                enhance resilience. By merging aesthetics with sustainable
                design principles, we create public spaces and architectural
                designs that enrich the urban fabric while preserving natural
                resources for future generations.
              </Typography>

              <h2>Transforming the Urban Landscape: </h2>
              <Typography
                variant="h6"
                gutterBottom
                align="justify"
                sx={{ lineHeight: "1.8", color: "grey" }}
              >
                Our passion for urban design, coupled with our expertise in
                public architecture, enables us to create dynamic and engaging
                spaces that inspire social interaction, cultural exchange, and
                economic growth. Through our dedication to excellence and our
                commitment to community-centric design, we continue to shape
                Romania’s public spaces for a brighter and more inclusive
                future.
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              {/* Right column (empty) */}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Office;
