import React, { useState } from "react";
import projects from "../data/projects";
import Project from "../components/project";
import { Grid, Button } from "@mui/material";

const Work = () => {
  const [selectedTag, setSelectedTag] = useState("all");

  const handleTagSelection = (tag: string) => {
    setSelectedTag(tag);
  };

  const filteredProjects =
    selectedTag === "all"
      ? projects
      : projects.filter((project) => project.tag === selectedTag);

  const buttonStyle = (tag: any) => ({
    transition: "background-color 0.3s, color 0.3s", // Smooth transition for color and background-color
  });
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        color="inherit"
        style={{ marginBottom: "40px" }}
      >
        <Grid item xs={12} sm="auto">
          <Button
            onClick={() => handleTagSelection("all")}
            size="large"
            fullWidth
            style={buttonStyle("all")}
          >
            All
          </Button>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button
            onClick={() => handleTagSelection("retail")}
            size="large"
            fullWidth
          >
            Retail
          </Button>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button
            onClick={() => handleTagSelection("logistic")}
            size="large"
            fullWidth
          >
            Logistic/ Industrial
          </Button>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button
            onClick={() => handleTagSelection("public")}
            size="large"
            fullWidth
          >
            Public
          </Button>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button
            onClick={() => handleTagSelection("educational")}
            size="large"
            fullWidth
          >
            {" "}
            Educational{" "}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {filteredProjects.map((project) => (
          <Grid item key={project._id} xs={12} sm={6} md={4}>
            <Project project={project} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Work;
