import images from "../data/projectImages";

const projects = [
  {
    _id: "1",
    name: "Airport Security Filter",
    image: images.airportSecurityFilter,
    tag: "retail",
    carousel: [
      {
        imgPath:
          "https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1654178182571-LWMPO8S9VQW6NUSIJN0S/EFFEKT_BIOME_%C2%A9EFFEKT_.jpg?format=2500w",
      },
      {
        imgPath:
          "https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1654178243106-Y1TI4TP8F2EKDY7VVVFM/EFFEKT_BIOME_%C2%A9EFFEKT_7.jpg?format=2500w",
      },
      {
        imgPath:
          "https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1654178266904-BLN3XQ53O4VMWMWGY38O/EFFEKT_BIOME_%C2%A9EFFEKT_10.jpg?format=2500w",
      },
      {
        imgPath:
          "https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1654178443391-GDDM09U7SG48L0ID9XW5/EFFEKT_BIOME_%C2%A9EFFEKT_36.jpg?format=2500w",
      },
    ],

    description: "Airport Security Filter, Sibiu",
  },
  {
    _id: "2",
    name: "Cisnadie Strip Mall",
    image: images.cisnadieStripMall,
    tag: "logistic",
    carousel: [
      {
        imgPath:
          "https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1654178182571-LWMPO8S9VQW6NUSIJN0S/EFFEKT_BIOME_%C2%A9EFFEKT_.jpg?format=2500w",
      },
      {
        imgPath:
          "https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1654178243106-Y1TI4TP8F2EKDY7VVVFM/EFFEKT_BIOME_%C2%A9EFFEKT_7.jpg?format=2500w",
      },
      {
        imgPath:
          "https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1654178266904-BLN3XQ53O4VMWMWGY38O/EFFEKT_BIOME_%C2%A9EFFEKT_10.jpg?format=2500w",
      },
      {
        imgPath:
          "https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1654178443391-GDDM09U7SG48L0ID9XW5/EFFEKT_BIOME_%C2%A9EFFEKT_36.jpg?format=2500w",
      },
    ],

    description:
      "Introducing the iPhone 11 Pro. A transformative triple-camera system that adds tons of capability without complexity. An unprecedented leap in battery life",
  },
  {
    _id: "3",
    name: "Hotel Pucioasa",
    image: images.hotelPucioasa,
    tag: "public",
    description:
      "Characterized by versatile imaging specs, the Canon EOS 80D further clarifies itself using a pair of robust focusing systems and an intuitive design",
  },
  {
    _id: "4",
    name: "Private House Bucharest",
    image: images.privateHouseBucharest,
    tag: "public",
    description:
      "The ultimate home entertainment center starts with PlayStation. Whether you are into gaming, HD movies, television, music",
  },
  {
    _id: "5",
    name: "Private House Harman",
    image: images.privateHouseHarman,
    tag: "educational",
    description:
      "Get a better handle on your games with this Logitech LIGHTSYNC gaming mouse. The six programmable buttons allow customization for a smooth playing experience",
  },
  {
    _id: "6",
    name: "Public Kindergarden Sibiu",
    image: images.publicKindergardenSibiu,
    description:
      "Meet Echo Dot - Our most popular smart speaker with a fabric design. It is our most compact smart speaker that fits perfectly into small space",
  },
];

export default projects;
