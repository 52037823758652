import { Link } from "react-router-dom";
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Box from "@mui/material/Box";
import { CardActionArea, ClassNameMap } from "@mui/material";
import { makeStyles } from "@mui/material/styles";
import { Theme, createStyles } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { ImageHomeProps } from "../types/general";

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 200,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.4, // Set the desired opacity on hover
    },
    "& .MuiTypography-root": {
      opacity: 1, // Show the project name on hover
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  transition: theme.transitions.create("opacity"), // Add transition for opacity
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0, // Set initial opacity to 0
  transition: theme.transitions.create("opacity"),
}));

const ImageHome: React.FC<ImageHomeProps> = ({ page }) => {
  //const classes = useStyles();
  return (
    <>
      <Box
        sx={{ display: "flex", flexWrap: "wrap", minWidth: 300, width: "100%" }}
      >
        <Link
          to={`/${page.name}`}
          style={{ textDecoration: "none", width: "100%" }}
        >
          <ImageButton
            focusRipple
            key={page.name}
            style={{
              width: "100%",
              height: 268,
            }}
          >
            <ImageSrc style={{ backgroundImage: `url(${page.image})` }} />
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <Image>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                sx={{
                  position: "relative",
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                  opacity: 0, // Hide the project name initially
                  transition: "opacity 0.3s ease", // Smooth transition for opacity
                  fontSize: 35,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {page.name}
              </Typography>
            </Image>
          </ImageButton>
        </Link>
      </Box>
    </>
  );
};

export default ImageHome;
