import React from "react";
import { useParams } from "react-router-dom";

import projects from "../data/projects";
import { ProjectProps } from "../types/general";
import ImageCarousel from "../components/imageCarousel";
import { Box, Grid, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

const ProjectScreen = () => {
  const { id: ProjectId } = useParams();
  const projectData = projects.find((p: any) => p._id === ProjectId);
  const project: ProjectProps | undefined = projectData
    ? { project: projectData }
    : undefined;
  console.log(project);

  return (
    <>
      {project && (
        <>
          <ImageCarousel />
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                {/* Left column (empty) */}
              </Grid>

              <Grid item xs={12} md={6}>
                <h2>{project.project.name}</h2>
                <Typography
                  variant="h6"
                  gutterBottom
                  align="justify"
                  sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
                >
                  The new housing product, Biome, represents a bold departure
                  from traditional construction methods, combining CREE
                  Buildings' proven modular timber-hybrid building system with
                  EFFEKT's bespoke design solutions. The result? A novel
                  approach to personalized and planet-conscious urban
                  living.≈numquam dignissimos laborum fugiat deleniti? Eum quasi
                  quidem quibusdam.
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  align="justify"
                  sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
                >
                  The pressing challenges of modern housing are well-documented:
                  a lack of affordable options, inadequate indoor climate and a
                  one-size-fits-all approach that fails to meet the diverse
                  needs of residents. These challenges are perpetuated by
                  unsustainable construction practices and a disjointed approach
                  to planning, where each building project starts from scratch
                  instead of focusing on long-term partnerships and quality
                  optimization over time.
                </Typography>
                <Grid spacing={2}>
                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Location
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Brasov, Romania
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Technical Architects
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        TRIPTIC Studio
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Lead Architects
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Aurel Basuc
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Team Architects
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Adrian Țuțuianu & Alexandru Vasile
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Client
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Primaria Brasov
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Function
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Sports
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Feasibility Study
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Seats
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        10.000
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Year
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        2023
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                {/* Right column (empty) */}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default ProjectScreen;
