const homepage = [
  {
    _id: "1",
    name: "work",
    image: "/images/Frederiksberg.jpg",
  },
  {
    _id: "2",
    name: "office",
    image: "/images/Fuglebakkevej.jpg",
  },
  {
    _id: "3",
    name: "contact",
    image: "/images/HEALCampus.jpg",
  },
];

export default homepage;
