import React from "react";
import Banner from "../components/banner";
import banners from "../data/banners";
import Box from "@mui/material/Box";
import { Button, Grid, Typography } from "@mui/material";
import Map from "../components/map";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { ContactForm } from "../components/contactForm";
import ModalForm from "../components/ModalForm";

const Contact = () => {
  return (
    <>
      <Grid item xs={12}>
        <Box id={banners.contact.title} sx={{ my: 4 }}>
          <Banner
            imageUrl={banners.contact.image}
            text={banners.contact.title}
          />
        </Box>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center vertically
            alignItems="center" // Center horizontally
            height="100%"
            textAlign="center" // Center text alignment
          >
            <div>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 900 }}>
                92a Orzari Str. Bucharest, Romania
              </Typography>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 900 }}>
                PO 021554
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={1}
                mb={2}
              >
                {" "}
                {/* Add marginBottom */}
                <PhoneIcon color="action" fontSize="large" />
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 900, color: "grey" }}
                >
                  <a
                    href="tel:+40732771959"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    0732 771 959
                  </a>
                </Typography>
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={1}
                mb={2}
              >
                <WhatsAppIcon color="action" fontSize="large" />
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 900, color: "grey" }}
                >
                  <a
                    href="https://wa.me/40730617496"
                    style={{ textDecoration: "none", color: "inherit" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    0730 617 496
                  </a>
                </Typography>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={1}
                mb={2}
              >
                <EmailIcon color="action" fontSize="large" />
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 900, color: "grey" }}
                >
                  <a
                    href="mailto:home@triptic.ro"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    home@triptic.ro
                  </a>
                </Typography>
              </Box>
            </div>
            {/* <Button
              variant="outlined"
              size="large"
              sx={{
                color: "black",
                borderColor: "black",
                borderWidth: "2px",
                borderRadius: 0,
                fontWeight: "bold",
                mt: 2, // Add top margin to the button
                "&:hover": {
                  borderColor: "black",
                  backgroundColor: "transparent",
                },
              }}
            >
              CONTACT US
            </Button> */}
            <ModalForm />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Map />
        </Grid>
      </Grid>
    </>
  );
};

export default Contact;
